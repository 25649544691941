import { useEffect, useState } from 'react';

import { getThemeStyles } from 'holded/lib/styles';
import { CtaSection } from 'holded/modules/cms/domain/components/cta';
import { CTATheme, defaultThemeValues } from 'holded/modules/cms/ui/sections/cta/CTATheme';
import Button from 'holded/modules/cms/ui/shared/components/Button';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';
const CTA2 = ({ title, titleSecondary, description, image, buttons, background }: CtaSection) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  useEffect(() => {
    const themeStyles = getThemeStyles(CTATheme.cta, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div>
      <div className={`relative xl:max-w-7xl mx-auto ${themeValues.background}`}>
        <div className="h-56 bg-blue-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 ">
          {image?.data && <NextImage media={image.data} classImg={'w-full !h-full object-cover'} />}
        </div>
        <div className={`relative max-w-7xl px-4 py-12 mx-auto sm:px-6 lg:px-8 lg:py-16`}>
          <div className={`md:ml-auto md:w-1/2 md:pl-10`}>
            <div>
              <p
                className={`${themeValues.titleSecondaryColor} text-b3 leading-6 font-semibold uppercase tracking-wider `}
              >
                {titleSecondary}
              </p>
              <p
                className={`${themeValues.titleColor} mt-2 text-h4 md:text-h3 font-extrabold tracking-tight sm:text-4xl`}
              >
                {title}
              </p>
              <p className={`${themeValues.descriptionColor} mt-3 text-b2 leading-7`}>{description}</p>
            </div>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                {buttons?.map((button) => (
                  <Button key={button.id} data={button}>
                    {button.text}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA2;
